// paper & background
$paper: #ffffff;

// primary
$primaryLight: #e3e8e8;
$primaryMain: #173e43;
// $primaryDark: #14383d;
$primaryDark: #1c1c1c;
$primaryDark1: #000936;
$primary200: #8b9fa1;
$primary800: #0d282c;

// secondary
$secondaryLight: #e8f6f5;
$secondaryMain: #3fb0ac;
$secondaryDark: #39a9a5;
$secondary200: #9fd8d6;
$secondary800: #299792;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #00c853;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey900: #212121;
$greyDark: #57606a;

//Blue
$blue50: #e3f2fd;
$blue500: #2196f3;
$blue600: #1e88e5;
$blue800: #1565c0;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #030708; // level 3
$darkPaper: #051114; // level 4

// dark 800 & 900
$darkLevel1: #02131d; // level 1
$darkLevel2: #051114; // level 2

// primary dark
$darkPrimaryLight: #e3e8e8;
$darkPrimaryMain: #3a5b5f;
$darkPrimaryDark: #14383d;
$darkPrimary200: #8b9fa1;
$darkPrimary800: #0d282c;

// secondary dark
$darkSecondaryLight: #e8f6f5;
$darkSecondaryMain: #3fb0ac;
$darkSecondaryDark: #39a9a5;
$darkSecondary200: #9fd8d6;
$darkSecondary800: #299792;

// text variants
$darkTextTitle: #ffffff;
$darkTextPrimary: #ffffff;
$darkTextSecondary: #ccd2eb;

// ==============================|| JAVASCRIPT ||============================== //

:export {
  // paper & background
  paper: $paper;

  // primary
  primaryLight: $primaryLight;
  primary200: $primary200;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;
  primaryDark1: $primaryDark1;
  primary800: $primary800;

  // secondary
  secondaryLight: $secondaryLight;
  secondary200: $secondary200;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondary800: $secondary800;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;

  // error
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;

  // orange
  orangeLight: $orangeLight;
  orangeMain: $orangeMain;
  orangeDark: $orangeDark;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  // grey
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey900: $grey900;
  greyDark: $greyDark;

  //Blue
  blue50: $blue50;
  blue500: $blue500;
  blue600: $blue600;
  blue800: $blue800;

  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  darkPaper: $darkPaper;
  darkBackground: $darkBackground;

  // dark 800 & 900
  darkLevel1: $darkLevel1;
  darkLevel2: $darkLevel2;

  // text variants
  darkTextTitle: $darkTextTitle;
  darkTextPrimary: $darkTextPrimary;
  darkTextSecondary: $darkTextSecondary;

  // primary dark
  darkPrimaryLight: $darkPrimaryLight;
  darkPrimaryMain: $darkPrimaryMain;
  darkPrimaryDark: $darkPrimaryDark;
  darkPrimary200: $darkPrimary200;
  darkPrimary800: $darkPrimary800;

  // secondary dark
  darkSecondaryLight: $darkSecondaryLight;
  darkSecondaryMain: $darkSecondaryMain;
  darkSecondaryDark: $darkSecondaryDark;
  darkSecondary200: $darkSecondary200;
  darkSecondary800: $darkSecondary800;
}
