// color variants
@import 'theme4.module.scss';
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

#firstName-error::first-letter {
  text-transform: capitalize;
}

#lastName-error::first-letter {
  text-transform: capitalize;
}
#authProviderId-error::first-letter {
  text-transform: capitalize;
}
#address-error::first-letter {
  text-transform: capitalize;
}
#address-error::first-letter {
  text-transform: capitalize;
}
#bio-error::first-letter {
  text-transform: capitalize;
}

td {
  word-break: break-all;
}

img {
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  // -webkit-text-fill-color: white !important;
}

header .logo {
  width: 170px;
  height: 34px;
}

.custom-phone {
  &.gradient {
    .react-international-phone-input-container {
      .react-international-phone-dial-code-preview {
        background: linear-gradient(274deg, #3f87ad -134.73%, #62d2a3 65.34%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;

        &:before {
          // background-image: url("../../public/assets/images/icon-arrow-dropdown-gradient.svg");
        }

        &:after {
          background: linear-gradient(274deg, #3f87ad -134.73%, #62d2a3 65.34%);
        }
      }
    }
  }

  label {
    display: block;
    // font: 500 0.875rem/1.214288 $poppins;
    margin-bottom: 4px;
    // color: $greyDark;
    color: #000936;
  }

  .react-international-phone-input-container {
    position: relative;
    border-radius: 16px;
    padding: 5px 14px;
    border: 1px solid #57606a;
    background: #ffffff;
    height: 60px;
    align-items: center;
    z-index: 3;
    // overflow: hidden;
    .react-international-phone-country-selector {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      z-index: 1;

      .react-international-phone-country-selector-button {
        opacity: 0;
      }
    }
    .react-international-phone-dial-code-preview {
      // font: 16px/1.25 $poppins;
      border: none;
      position: relative;
      padding: 0 28px 0 0;
      color: $primaryDark1;

      &:before {
        content: '';
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translateY(-50%);
        background: url('../images/icon-arrow-dropdown.svg') no-repeat;
        width: 20px;
        height: 20px;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        background: $primaryDark1;
        width: 1px;
        height: 23px;
      }
    }

    .react-international-phone-input {
      width: 100%;
      border: none;
      color: $primaryDark1;
      // font: 16px/1.25 $poppins;

      &--disabled {
        background: transparent;
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }
}

.custom-phone-select {
  border-radius: 16px;
  padding: 5px 14px;
  border: 1px solid #57606a;
  background: #ffffff;

  .MuiInputBase-root {
    border: none;

    .MuiSelect-select {
      padding: 2px 30px 2px 0;
      background: transparent;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 60px;
        background: #000;
        transform: translateY(-50%);
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }

  .MuiOutlinedInput-root {
    border: none;
    border-radius: 0;
    padding: 0;
    background: transparent;
  }

  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      padding-left: 10px;
    }
  }
}

.detail-list-desc {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 0;

  li {
    padding: 0;
    > div {
      width: 100%;
      // align-items: center;
      > span.MuiTypography-root {
        font-weight: 400;
      }

      > .MuiTypography-root {
        width: 10px;

        &:first-child {
          width: 178px;
          // color: $grey600;
          padding-right: 10px;
          display: flex;
          // align-items: center;

          img,
          svg {
            margin-right: 8px;
          }
        }

        &:nth-child(2) {
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.4;
        }

        &:last-child {
          width: calc(100% - 188px);
          padding-left: 40px;
        }

        // .MuiChip-root {
        //   min-width: 160px;
        //   padding: 5px 8px;
        //   text-align: center;

        //   &:hover {
        //   }

        //   .MuiChip-label {
        //     font-size: 12px;
        //     line-height: 1;
        //     font-weight: 500;
        //     color: #000;
        //   }
        // }
      }
    }
  }

  .lists {
    padding: 0;
    list-style: disc;
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    li {
      color: $primaryDark1;
      position: relative;
      padding-left: 12px;
      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 4px;
        height: 4px;
        background-color: $primaryDark1;
        border-radius: 50%;
      }
      // list-style: disc;
    }
  }
}

.chip {
  padding: 5px;
  min-width: 160px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 50px;
  color: #000;
  text-align: center;
  display: inline-block;

  &.active,
  &.visible,
  &.success {
    background: #b9f6ca;
  }

  &.completed-transaction {
    background: #b9f6ca;
    min-width: 79px;
  }

  &.pending-transaction {
    background: #ffe57f;
    min-width: 79px;
  }
  &.rejected-transaction {
    background: #d84315;
    min-width: 79px;
  }
  &.refunded-transaction {
    background: #90caf9;
    min-width: 79px;
  }

  &.processing-transaction {
    background: #f2994a;
    min-width: 79px;
  }
  &.pending {
    background: #90caf9;
  }
  &.approved {
    background: #b39ddb;
  }
  &.declined {
    background: #f2994a;
  }
  &.disabled {
    background: #d84315;
  }

  &.hidden {
    background: #ffe57f;
  }
  &.booked {
    background: #9ec8e9;
    min-width: 79px;
  }
  &.finished {
    background: #e0e0e0;
    min-width: 79px;
  }

  &.finished-subscription {
    background: #e0e0e0;
  }
  &.cancelled {
    background: #fbe9e7;
    min-width: 79px;
  }
  &.cancelled-subscription {
    background: #fbe9e7;
  }
  &.created-subscription {
    background: #ffe57f;
  }
  &.updated-subscription {
    background: #9ec8e9;
  }
}

// setting td to top
.vertical {
  tbody {
    tr {
      td {
        vertical-align: top;
      }
    }
  }
}

.appointment-history-table {
  .MuiTableContainer-root {
    padding-inline: 0;
  }
}
